@import './resources/fonts/fonts.css';

$primary: #000;
$secondary: #6c6c87;
$bg: #d5d5d5;
$input: #f6f6f6;
$border: #ececef;
$text: #6c6c87;
// $font-family: 'Roboto', sans-serif;
$font-family: 'Montserrat', sans-serif;
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
p,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
button {
  outline: none;
  user-select: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  background: white;
  color: $text;
  font-family: $font-family !important;
  font-size: 16px;
  line-height: 1;
  margin: 0;
}
a {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}
img {
  max-width: 100%;
}
p {
  font-family: $font-family;
}
html {
  scroll-behavior: smooth;
}
.App {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.content {
  padding: 15px;
}
.contentArea {
  width: 100%;
}
.notificacoes {
  width: 100%;
}
.backQrcode {
  width: 100%;
}
.notificacao {
  padding-top: 75px;
  width: 100%;
  &Item {
    align-items: center;
    background-color: $primary;
    border-radius: 100%;
    bottom: 10px;
    display: flex;
    font-size: 15px;
    height: 70px;
    justify-content: center;
    position: fixed;
    right: 10px;
    width: 70px;
    img {
      max-height: 35px;
    }
    &Quant {
      align-items: center;
      background-color: #fcc860;
      border-radius: 100px;
      color: $primary;
      display: flex;
      justify-content: center;
      height: 30px;
      position: absolute;
      right: 0;
      top: -10px;
      width: 30px;
    }
  }
}
.title {
  color: $primary;
  font-size: 18px;
  line-height: normal;
  margin-bottom: 15px;
  width: 100%;
}
.title_bold {
  font-weight: bold;
}
input {
  font-family: $font-family;
}
.description {
  color: $text;
  font-size: 16px;
  line-height: normal;
  width: 100%;
}
.leftTitle {
  text-align: left;
}
.centerTitle {
  text-align: center;
}
.rightTitle {
  text-align: right;
}
.ui-input-title {
  color: $text;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
  width: 100%;
}
button {
  font-family: $font-family;
}
.ui-input {
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px $text solid;
  color: $text;
  font-size: 16px;
  font-family: $font-family;
  margin-bottom: 20px;
  outline: none;
  padding: 5px 0;
  width: 100%;
}
.buttonFixed {
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 100%;
  button {
    color: white;
    font-size: 16px;
    font-weight: bold;
    background-color: $primary;
    border-radius: 5px;
    border: none;
    padding: 15px 10px;
    width: calc(100% - 30px);
  }
}
input {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}

@import './src/pages/intro/intro';
@import './src/pages/menu/menu';
@import './src/pages/home/home';
@import './src/pages/notifications/notifications';
@import './src/pages/pedidos/pedidos';
@import './src/pages/lojas/lojas';
@import './src/pages/carrinho/carrinho';
@import './src/pages/login/login';
@import './src/pages/card/card';
@import './src/pages/user/qrcode';
@import './src/pages/perfil/perfil';
@import './src/pages/qrcode/qrcode';

body {
  @media (min-width: 500px) {
    margin: 0 auto;
    max-width: 380px;
  }
}