.cartao {
  .selectArea {
    align-items: center;
    border-radius: 100%;
    border: 1px $primary solid;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-right: 15px;
    width: 18px;
    &Active {
      background-color: $primary;
      border-radius: 100%;
      height: 14px;
      width: 14px;
    }
  }
  .cartaoList {
    li {
      margin-bottom: 15px;
      .cardSelected {
        align-items: center;
        border: 1px white solid;
        box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        justify-content: space-between;
        width: 100%;
        .infoLeft {
          align-items: center;
          color: $text;
          display: flex;
          flex-direction: row;
          font-size: 16px;
          font-weight: 300;
          margin-right: 15px;
          padding-left: 15px;
          img {
            margin-right: 15px;
            max-width: 35px;
          }
        }
        .number {
          color: $primary;
          font-size: 16px;
          font-weight: bold;
          margin-left: 15px;
        }
      }
      .selected {
        border: 1px $primary solid;
      }
    }
  }
  .other {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 35px;
    width: 100%;
    button {
      background-color: white;
      border-radius: 3px;
      color: $primary;
      font-size: 16px;
      border: 1px $primary solid;
      padding: 15px 30px 13px 30px;
    }
  }
  .proximoArea {
    position: fixed;
    bottom: 15px;
    left: 15px;
    width: 100%;
    button {
      color: white;
      font-size: 16px;
      font-weight: bold;
      background-color: $primary;
      border-radius: 5px;
      border: none;
      padding: 15px 10px;
      width: calc(100% - 30px);
    }
  }
  .activeBG {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: all 0.3s;
    width: 100%;
  }
  .modalPay {
    left: 0;
    bottom: 50px;
    position: fixed;
    transition: all 0.3s;
  }
  .hide {
    left: 0;
    bottom: -100%;
    position: fixed;
    transition: all 0.3s;
  }
  .hidecvv {
    background-color: white;
    border-radius: 10px 10px 0 0;
    bottom: -100%;
    left: 0;
    padding: 30px 15px 15px 15px;
    position: fixed;
    width: calc(100% - 30px);
  }
  .cvvArea {
    background-color: white;
    border-radius: 10px 10px 0 0;
    bottom: 0;
    left: 0;
    padding: 30px 15px 15px 15px;
    position: fixed;
    width: calc(100% - 30px);
    .cvvTitle {
      color: $primary;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 15px;
      text-align: center;
      width: 100%;
    }
    .inputArea {
      input {
        text-align: center;
      }
    }
    .inputLead {
      color: $text;
      font-size: 14px;
      margin-top: -15px;
      text-align: center;
      width: 100%;
    }
    .buttonPay {
      margin-top: 25px;
      button {
        color: white;
        font-size: 16px;
        font-weight: bold;
        background-color: $primary;
        border-radius: 5px;
        border: none;
        padding: 15px 10px;
        width: 100%;
      }
    }
  }
}
.success {
  text-align: center;
  img {
    margin: 20px 0;
  }
  .titleSuccess {
    color: $primary;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
  }
  .textSuccess {
    color: $text;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
  }
  .buttonAvancar {
    margin-top: 25px;
    button {
      color: white;
      font-size: 16px;
      font-weight: bold;
      background-color: $primary;
      border-radius: 5px;
      border: none;
      padding: 15px 10px;
      width: 100%;
    }
  }
}
.cartaoAdd {
  .tipoArea {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin: 15px 0 25px 0;
    button {
      background-color: white;
      border: 1px $primary solid;
      color: $primary;
      font-size: 16px;
      font-weight: 300;
      padding: 15px 30px;
      width: 50%;
    }
    .selected {
      background-color: $primary;
      color: white;
    }
    .left {
      border-radius: 4px 0 0 4px;
    }
    .right {
      border-radius: 0 4px 4px 0;
    }
  }
  .divideInput {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    .left {
      width: 45%;
    }
    .right {
      width: 45%;
    }
  }
  .buttonPay {
    position: fixed;
    bottom: 15px;
    left: 15px;
    width: 100%;
    button {
      color: white;
      font-size: 16px;
      font-weight: bold;
      background-color: $primary;
      border-radius: 5px;
      border: none;
      padding: 15px 10px;
      width: calc(100% - 30px);
    }
  }
}
.buttonSucesso {
  left: 0!important;
}
.newText {
  margin-bottom: 5px;
  text-align: center;
  width: 100%;
}
.newTextBold {
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.payNormal {
  bottom: 0!important;
  margin-top: 20px;
  left: 0!important;
  position: relative!important;
  button {
    width: 100%!important;
  }
}