.notifications {
  li {
    border-bottom: 2px $border solid;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .imageArea {
    border-radius: 5px;
    background-color: #fff;
    height: 70px;
    overflow: hidden;
    width: 70px;
    .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
    }
  }
  .text {
    width: calc(100% - 70px);
    padding-left: 10px;
    b {
      font-size: 16px;
      line-height: normal;
      font-weight: bold;
    }
  }
}