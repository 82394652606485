.carrinho {
  position: relative;
  .observacoes {
    margin-bottom: 10px;
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        align-items: center;
        background-color: $primary;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 6px 10px;
        .texto {
          color: white;
          line-height: normal;
          padding-left: 5px;
          padding-right: 5px;
          width: calc(98% - 30px);
        }
        .button {
          margin-top: 2px;
          text-align: right;
          width: 30px;
          img {
            max-width: 20px;
          }
        }
      }
    }
  }
  .areaSelected {
    background-color: transparent;
    bottom: 15px;
    left: 5%;
    position: fixed;
    width: 90%;
    .areaSelectedBox {
      align-items: center;
      background-color: $primary;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      padding: 15px 10px;
      .quantArea {
        color: $primary;
        align-items: center;
        background-color: white;
        border-radius: 5px;
        display: flex;
        height: 35px;
        justify-content: center;
        width: 35px;
      }
      .valueArea {
        color: white;
        font-size: 18px;
        font-weight: bold;
      }
      .nextArea {
        img {
          max-width: 22px;
        }
      }
    }
  }
  .spaceFixed {
    margin-top: 80px;
  }
  .cupomArea {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    .cuponsTitle {
      align-items: center;
      color: $primary;
      display: flex;
      img {
        max-width: 40px;
        margin-right: 20px;
      }
    }
    .quantiodadeCupom {
      align-items: center;
      background-color: #fcc860;
      border-radius: 5px;
      color: white;
      display: flex;
      height: 30px;
      justify-content: center;
      width: 30px;
    }
  }
  .pedidoListaArea {
    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      width: 100%;
      .photoarea {
        align-items: center;
        box-shadow: 3px 4px 9px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        width: 60px;
        .image {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 50px;
          width: 50px;
        }
      }
      .productInfo {
        padding-left: 15px;
        width: calc(100% - 60px);
        .productName {
          color: $primary;
          margin-bottom: 10px;
          width: 100%;
        }
        .moreLessArea {
          align-items: center;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          width: 100%;
          .somaDiminui {
            align-items: center;
            display: flex;
            flex-direction: row;
          }
          .less {
            align-items: center;
            border-radius: 5px;
            border: 1px $primary solid;
            display: flex;
            height: 26px;
            justify-content: center;
            width: 26px;
            img {
              max-width: 20px;
            }
          }
          .quantidadeMorearea {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-left: 10px;
            .quantidade {
              align-items: center;
              color: white;
              border-radius: 5px 0 0 5px;
              border-right: 1px white solid;
              background-color: $primary;
              display: flex;
              flex-direction: row;
              height: 34px;
              justify-content: center;
              width: 25px;
            }
            .more {
              align-items: center;
              background-color: $primary;
              border: none;
              border-radius: 0 5px 5px 0;
              color: white;
              display: flex;
              font-weight: 300;
              height: 34px;
              padding: 2px 10px;
              img {
                margin-right: 8px;
                max-height: 16px;
                filter: invert(100%);
              }
            }
          }
          .valorProduto {
            color: $primary;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.cupom {
  &Add {
    form {
      align-items: baseline;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    input {
      border-radius: 0;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 1px $primary solid;
      color: $text;
      font-size: 16px;
      outline: none;
      padding: 5px 0;
      width: 65%;
    }
    button {
      background-color: $primary;
      border: 1px $primary solid;
      border-radius: 5px;
      box-shadow: none;
      color: white;
      font-size: 16px;
      padding: 8px 5px;
      width: 30%;
    }
  }
  &Not {
    border: 1px $primary solid;
    border-radius: 5px;
    padding: 20px 0px 18px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0;
    width: 100%;
    .cupomNotTitle {
      color: $primary;
      font-weight: bold;
      font-size: 16px;
      padding-left: 15px;
    }
    .selectArea {
      align-items: center;
      border-radius: 100%;
      border: 1px $primary solid;
      display: flex;
      height: 18px;
      justify-content: center;
      margin-right: 15px;
      width: 18px;
      &Active {
        background-color: $primary;
        border-radius: 100%;
        height: 14px;
        width: 14px;
      }
    }
  }
  .cupomLista {
    li {
      background-color: white;
      box-shadow: 3px 4px 9px 1px rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
      padding: 10px;
      .cupomListaTopo {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
        .nome {
          align-items: center;
          color: $primary;
          display: flex;
          font-weight: bold;
          flex-direction: row;
          font-size: 16px;
          img {
            margin-right: 10px;
            max-width: 40px;
          }
        }
        .selectArea {
          align-items: center;
          border-radius: 100%;
          border: 1px $primary solid;
          display: flex;
          height: 18px;
          justify-content: center;
          width: 18px;
          &Active {
            background-color: $primary;
            border-radius: 100%;
            height: 14px;
            width: 14px;
          }
        }
      }
      .text {
        color: $text;
        font-size: 14px;
        line-height: normal;
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
      }
      .termina {
        color: $text;
        font-size: 12px;
        text-align: right;
        width: 100%;
      }
    }
  }
}
