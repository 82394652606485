.login {
  &Title {
    color: $primary;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
  &Text {
    color: $text;
    font-size: 14px;
    line-height: normal;
    margin: 20px 0;
    text-align: center;
    width: 100%;
  }
  .buttonArea {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .hasAccount {
      color: $primary;
      font-size: 14px;
      text-align: center;
      width: 50%;
      a {
        color: $primary;
      }
    }
    button {
      background-color: $primary;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 16px;
      font-weight: 300;
      padding: 15px;
      width: 50%;
      a {
        color: white;
      }
    }
  }
  .otherOption {
    color: $text;
    font-size: 14px;
    font-weight: 300;
    margin: 20px 0;
    text-align: center;
    width: 100%;
  }
  .loginButtons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .facebook {
      align-items: center;
      background-color: #3b5998;
      border: none;
      border-radius: 5px;
      color: white;
      display: flex;
      font-size: 16px;
      font-weight: 300;
      justify-content: center;
      padding: 10px 15px 8px 15px;
      width: 100%;
      img {
        max-height: 25px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
    .google {
      align-items: center;
      background-color: white;
      border: 1px $text solid;
      border-radius: 5px;
      color: $text;
      display: flex;
      font-size: 16px;
      font-weight: 300;
      justify-content: center;
      padding: 10px 15px;
      width: 48%;
      img {
        margin-right: 10px;
        max-width: 25px;
      }
    }
  }
}
.token {
  .tokenText {
    color: $text;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    margin-top: 50px;
    text-align: center;
    width: 100%;
    b {
      font-weight: bold;
    }
  }
  .tokenTitle {
    color: $primary;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    margin: 15px 0 20px 0;
    width: 100%;
  }
  .tokenArea {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    .tokenItem {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%!important;
      input {
        border-radius: 3px;
        border: 1px $primary solid;
        color: $primary;
        font-family: $font-family;
        font-size: 18px;
        margin: 0 10px;
      }
    }
  }
  .resendArea{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    width: 100%;
    .resend {
      color: white;
      font-size: 16px;
      font-weight: bold;
      background-color: $primary;
      border-radius: 5px;
      border: none;
      padding: 15px 10px;
      width: 150px;
    }
    .time {
      color: $primary;
      font-size: 16px;
      font-weight: 300;
    }
  }
  .proximoArea {
    position: fixed;
    bottom: 15px;
    left: 15px;
    width: 100%;
    button {
      color: white;
      font-size: 16px;
      font-weight: bold;
      background-color: $primary;
      border-radius: 5px;
      border: none;
      padding: 15px 10px;
      width: calc(100% - 30px);
    }
  }
}
