/* latin */
/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url(./roboto-300.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
} */
/* latin */
/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url(./roboto-400.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
} */
/* latin */
/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url(./roboto-500.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
} */
/* latin */
/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url(./roboto-700.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
} */
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./material.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}


/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./montserrat-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Light'), local('Montserrat-Light'),
       url('./montserrat-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./montserrat-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./montserrat-v15-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./montserrat-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./montserrat-v15-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('./montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./montserrat-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./montserrat-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./montserrat-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('./montserrat-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./montserrat-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./montserrat-v15-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./montserrat-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./montserrat-v15-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./montserrat-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('./montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./montserrat-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./montserrat-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./montserrat-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./montserrat-v15-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}